import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dialog"
export default class extends Controller {
  static targets = ["template"];

  dialogTag = document.getElementById(this.element.getAttribute("data-dialog-id"));

  turboStart() {
    const clone = this.templateTarget.content.cloneNode(true);
    const link = clone.firstElementChild;

    link.setAttribute("hidden", "");
    this.element.appendChild(clone);
    this.#turbo_confirm_method();
    link.click();
    link.remove();
  };

  open() {
    this.dialogTag.showModal();
  };

  close() {
    this.element.close();
  }

  #turbo_confirm_method() {
    Turbo.setConfirmMethod((message) => {
      const dialogTag = this.dialogTag;
      dialogTag.querySelector("#message").innerHTML = message;

      if (this.element.hasAttribute("data-dialog-require-code")) this.#turbo_required_code();

      dialogTag.showModal();

      return new Promise((resolve, reject) => {
        dialogTag.addEventListener("close", () => {
          if (this.element.hasAttribute("data-dialog-require-code")) {
            resolve(dialogTag.returnValue == "confirm" && this.#isRequireCodeCorrect());
          } else {
            resolve(dialogTag.returnValue == "confirm");
          };
        }, { once: true });
      });
    });
  };

  #turbo_required_code() {
    const confirmButton = this.dialogTag.querySelector("#confirm");
    const requireCode = this.element.getAttribute("data-dialog-require-code");
    let requireCodeInput = this.dialogTag.querySelector("input");

    confirmButton.disabled = true;

    requireCodeInput.addEventListener("input", (event) => {
      confirmButton.disabled = !(event.target.value == requireCode);
    });
  };

  #isRequireCodeCorrect() {
    const requireCode = this.element.getAttribute("data-dialog-require-code");
    let requireCodeInput = this.dialogTag.querySelector("input");

    return requireCodeInput.value == requireCode;
  };
};
